import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout blog={true}>
      <div className="about full-page-module">
        <h1>About</h1>
        <div className="about-details">
          <p>
          Computer Parts and Guides - "Refresh" educates you on how to refresh your computer with new parts. It's a blog with hand written content that walks users through how to build PCs and acquire high-demand (newest) parts for their PC builds. 
          </p>

          <p>
Which products will be promoted?
The  blog will recommend the latest and best PC products for their new PC hobby builds. We intend to promote consumer electronics PC parts. 
</p>
<p>
Who is it for?
This is for hobbyists, professionals, and consumers of all age groups to learn how to build computers and to seek help on how to acquire the latest computer hardware products.
</p>
<p>
What can users do?
Users can read about how to build a PC with the latest parts and also see other PC builds that are posted from the community. It's a great resource for people researching which new PC parts to buy and how to put the latest parts together.
</p>
        </div>
      </div>
    </Layout>
  )
}
